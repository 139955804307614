import { GoogleOAuthProvider, GoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import React, { useState } from 'react';
import './Popup.css';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';


function LoginPopup({ onClose, onLoginSuccess, showGuest = true, saveOnLocalStorage = true }) {
    useGoogleOneTapLogin({
        onSuccess: credentialResponse => {
            handleLoginSuccess(credentialResponse);
        },
        onError: () => {
            console.log('Google One-Tap Failed');
        },
    });

    const handleLoginSuccess = (credentialResponse) => {
        try {
            var token = credentialResponse.credential;
            const decodedToken = jwtDecode(token);
            const email = decodedToken.email;
            localStorage.setItem('idle-journey.login', decodedToken.sub)
            localStorage.setItem('idle-journey.email', email)
            onLoginSuccess('google')
        } catch {
            handleLoginError()
        }
    };

    const handleGuestLogin = () => {
        onLoginSuccess('guest');
    };

    const handleLoginError = () => {
        console.log('Google Login Failed');
        toast.error('Login failed, please try again.');
    };

    return (
        <div className="popup-overlay" onClick={onClose}>
            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                <div style={{
                display: 'flex', // Ensures the div is a flex container
                justifyContent: 'center', // Centers the child horizontally
            }}>
                <GoogleLogin
                    onSuccess={credentialResponse => {
                        handleLoginSuccess(credentialResponse);
                    }}
                    onError={handleLoginError}
                    theme="outline"
                    text="continue_with"
                />
                </div>
                {showGuest ?
                        <button className="guest-login-btn" onClick={handleGuestLogin}>
                        Continue as Guest
                        </button>
                     : null
                }
            </div>
        </div>
    );
}

export default LoginPopup;