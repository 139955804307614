import React, { useState } from 'react';
import UnityWebGLStag from './UnityWebGL-stag';

const PlayPageStag = () => {
    const containerStyle = {
        width: '100svw',
        height: '100svh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative', // Needed for absolute positioning of the background
        overflow: 'hidden' // Ensures no overflow from the background
    };

    const webGLStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100svw',
        height: '100svh',
    }
    return (
        <div style={containerStyle}>
            <div className="background-video">
                <div className="static-background">
                    <video
                        autoPlay
                        loop
                        muted
                        playsInline
                        className="static-background"
                        src="https://storage.googleapis.com/unity-player/unity-player/site-bg.mov"
                        type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
            <div style={webGLStyle}>
                <UnityWebGLStag />
            </div>
        </div>
    );
};

export default PlayPageStag;
