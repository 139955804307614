import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import PlayPage from './play-page';
import PlayPageStag from './play-page-staging';
import LandingPage from './Landingpage';

const clientId = "970007013608-431v324bim61h3h9rnua3jdc9an8tu18.apps.googleusercontent.com";

function LandingPageWrapper() {
  return <LandingPage />;
}

function PlayPageWrapper() {
  const location = useLocation();
  const { fromLogin, userId, userEmail } = location.state || {};  // Ensure defaults in case state is undefined
  return fromLogin ? <PlayPage /> : <Navigate to="/" replace />;
}

function PlayPageStagingWrapper() {
  const location = useLocation();
  const { fromLogin, userId, userEmail } = location.state || {};  // Ensure defaults in case state is undefined
  return fromLogin ? <PlayPageStag /> : <Navigate to="/" replace />;
}

function App() {
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="light" />
      <Router>
        <Routes>
          <Route path="/" element={<LandingPageWrapper />} />
          <Route path="/play" element={<PlayPageWrapper />} />
          <Route path="/play-stag" element={<PlayPageStagingWrapper />} />
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;