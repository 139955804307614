function PurchasePopup() {
    return (
        <div className="popup">
            <div className="popup-content">
                <h1>Purchase Game</h1>
                <p>Details for purchasing the game</p>
            </div>
        </div>
    );
}

export default PurchasePopup;