import React, { useState, useEffect, Fragment } from 'react';
import { Unity, useUnityContext } from 'react-unity-webgl';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation } from 'react-router-dom';
import LoginPopup from './LoginPopup';
import { toast } from 'react-toastify';


// Initialize Stripe
const stripePromise = loadStripe('pk_live_51NT2qjKbrBnZCTieCljhuohf42XmTzHrjtCsjlZIqpxVm2SY6RsO49QC6gYzlNjJgwYne2MJhZwosME2uUHLAKiV00iTE2PSpF');

/*
  * Deploy new client version: upload files to s3;
  * make sure files have: $ gsutil setmeta -h "Cache-Control:public, max-age=31536000" gs://unity-player/unity-player/web-player.data
  * update these URLs to point to new client version:
  * Deploy frontend server;
  * 
  * TODO: use a server side config instead of directly in code; we dont want to have to redeploy because of a config change;
  * its fine for now tho...
*/

const localContext = {
  loaderUrl: `unity-player/web-player.loader.js`,
  dataUrl: `unity-player/web-player.data`,
  frameworkUrl: `unity-player/web-player.framework.js`,
  codeUrl: `unity-player/web-player.wasm`,
}

const PLAYER_V = "v8"
const prodContext = {
  loaderUrl: `https://storage.googleapis.com/unity-player/unity-player/web-player-${PLAYER_V}.loader.js`,
  dataUrl: `https://storage.googleapis.com/unity-player/unity-player/web-player-${PLAYER_V}.data`,
  frameworkUrl: `https://storage.googleapis.com/unity-player/unity-player/web-player-${PLAYER_V}.framework.js`,
  codeUrl: `https://storage.googleapis.com/unity-player/unity-player/web-player-${PLAYER_V}.wasm`,
}

const useLocal = false
const apiURL =  "https://www.idle-journey.com/api/v1" //"http://localhost:8080/api/v1"

const UnityWebGLStag = () => {
  const { unityProvider, loadingProgression, isLoaded, sendMessage } = useUnityContext( useLocal ? localContext : prodContext);
  const location = useLocation();
  const { fromLogin, userId, userEmail } = location.state || {};  // Ensure defaults in case state is undefined
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [guestId, setGuestId] = useState('');

  const closeLoginPopup = () => setShowLoginPopup(false);

  const handleLoginSuccess = () => {
    closeLoginPopup();
    const googleUserId = localStorage.getItem('idle-journey.login');
    const email = localStorage.getItem('idle-journey.email');

    if (!googleUserId || googleUserId === undefined || !email || email === undefined) {
      toast.error('Failed to login with google');
      return;
    }
    const profileUrl = `${apiURL}/save-profile/${googleUserId}`;
    fetch(profileUrl)
    .then(response => {
        if (response.status === 404) {
            linkSocialAccount(guestId, googleUserId);
        } else {
            toast.success('Succesfully logged in with google');
            window.location.reload();
        }
    })
    .catch(error => {
        toast.error('Network or server error occurred during profile verification');
        console.error('Error:', error);
    });
};

const linkSocialAccount = (userId, socialId) => {
    const url = `${apiURL}/link-social-account`;
    const data = {
        user_id: userId,
        social_id: socialId
    };

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
        if (data.error) {
            toast.error(data.error);
        } else {
          toast.success('Account linked successfully!');
            window.location.reload();
        }
    })
    .catch((error) => {
        toast.error('Network or server error occurred during account linking');
        console.error('Error:', error);
    });
};


  const checkForUnityLoaderAndSendUserId = () => {
    const googleUserId = localStorage.getItem('idle-journey.login');
    const email = localStorage.getItem('idle-journey.email')

    if (!googleUserId || googleUserId == undefined || !email || email == undefined) {
      return;
    }

    let retryCount = 0;
    const maxRetries = 10; // Retry for a maximum of 5 seconds

    const interval = setInterval(() => {
      if (sendMessage) {
        console.log(googleUserId)
        sendMessage('Bootstrap', 'ReceiveGoogleUserId', googleUserId+'__'+email);
        clearInterval(interval);
      } else {
        console.error('Didnt send Id');
        retryCount += 1;
        if (retryCount >= maxRetries) {
          clearInterval(interval); // Stop retrying after 5 seconds
          console.error('Unable to send Google User ID to Unity after multiple attempts.');
        }
      }
    }, 500); // Retry every second
  };

  const PurchaseFlowError = () => {
    sendMessage('SERVICE_INSTANCE_HTTPPERSISTENCYLAYERENUMERATOR', 'CheckoutComplete', 'fail');
  }

  const LogError = s => {
    if (useLocal) {
      console.error(s)
    }
  }

  useEffect(() => {
    if (isLoaded) {
      checkForUnityLoaderAndSendUserId();
    }

    if (!fromLogin) {
      toast.warning('Link your account to google to save your progression.')
      window.location.href = '/';
      return;
    }
  
    window.socialLogout = async function() {
      localStorage.removeItem('idle-journey.login')
      localStorage.removeItem('idle-journey.email')
      window.location.href = '/';
    }

    window.socialLogin = async function(userId) {
      console.log('Social login' , userId)
      setGuestId(userId);
      setShowLoginPopup(true); // Display the login popup
    }

    window.initiateStripePurchase = async function (packId, userId) {
      console.log(packId, userId)
      try {  
        const stripe = await stripePromise;
        if (!stripe) {
          LogError("Stripe initialization failed");
          PurchaseFlowError();
          return;
        }
  
        // Call backend to create the Stripe session
        const response = await fetch(apiURL+'/create-checkout-session', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ packId: packId , playerId: userId }),
        });
  
        if (!response.ok) {
          LogError("Failed to create Stripe checkout session");
          PurchaseFlowError();
          return;
        }
  
        const session = await response.json();
        if (!session.sessionId) {
          LogError("Invalid session ID returned from backend");
          PurchaseFlowError();
          return;
        }
  
        const { error } = await stripe.redirectToCheckout({
          sessionId: session.sessionId,
        });
  
        if (error) {
          LogError('Error redirecting to Stripe:', error.message);
          PurchaseFlowError();
          return;
        }
  
        // Optionally handle successful initiation here if needed
      } catch (err) {
        LogError('An unexpected error occurred during the Stripe purchase flow:', err);
        PurchaseFlowError();
      }
    };
  
  }, [isLoaded]);

  return (
    <Fragment>
      <div style={{ position: 'relative', width:'100%', height: '100svh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>        
      {showLoginPopup && <LoginPopup onClose={closeLoginPopup} onLoginSuccess={handleLoginSuccess} showGuest={false} />}

        {!isLoaded && (
          <div style={{
            position: 'absolute',
            width: '300px',
            height: '30px',
            backgroundColor: '#333',
            borderRadius: '15px',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            {/* Progress Fill */}
            <div style={{
              height: '100%',
              width: `${Math.max(loadingProgression * 100, 1)}%`, // Ensure a minimum width for visual
              backgroundColor: '#4CAF50',
              borderRadius: '15px',
              transition: 'width 0.5s ease-out',
              position: 'absolute',
              left: 0, // Start expanding from the left
              top: 0  // Align to the top of the container
            }} />
            {/* Static Centered Text */}
            <span style={{
              color: 'white',
              zIndex: 2 // Ensure text is above the progress fill
            }}>
              Loading... {Math.floor(loadingProgression * 100)}%
            </span>
          </div>
        )}
        <Unity
          unityProvider={unityProvider}
          style={{  width: '100%', height: '100svh' }}
        />
      </div>
    </Fragment>
  );
};

export default UnityWebGLStag;