import React, { useState } from 'react';
import './LandingPage.css'; // Import the CSS for styling
import gameLogo from './images/idle-journey-logo-3d.png';
import steam from './images/steam.png';
import apple from './images/apple-logo.png';
import android from './images/android-logo.png';
import LoginPopup from './LoginPopup';
import PurchasePopup from './PurchasePopup';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';


function LandingPage() {
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [showPurchasePopup, setShowPurchasePopup] = useState(false);
    const navigate = useNavigate();


    const handlePlayNowClick = () => {
        const googleUserId = localStorage.getItem('idle-journey.login');
        const email = localStorage.getItem('idle-journey.email');
        const cantPlay = (!googleUserId || googleUserId == undefined || !email || email == undefined)

        if (!cantPlay) {
            navigate('/play', { state: { fromLogin: 'google', userId: googleUserId, userEmail: email } });
        } else {
            setShowLoginPopup(true);
        }
    };

    const closeLoginPopup = () => {
        setShowLoginPopup(false);
    };

    const handleLoginSuccess = (source) => {
        if (source == 'guest'){
            navigate('/play', { state: { fromLogin: 'guest'} });
            return;
        }
        if (source == 'google') {
            handlePlayNowClick()
        }
      };

    return (
        <div className="landing-page">
            <div className="background-video">
                <div className="static-background">
                    <video
                        autoPlay
                        loop
                        muted
                        playsInline
                        className="static-background"
                        src="https://storage.googleapis.com/unity-player/unity-player/site-bg.mov"
                        type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
            <div className="content">
                <div className="button-container" onClick={handlePlayNowClick}>
                    <img src={gameLogo} alt="Game Logo" className="game-logo" />
                    <button className="play-now-button">Play now</button>
                </div>
                <label className='coming-soon-label'>Coming Soon:</label>
                <div className="store-links">
                    <div className="store-button-container">
                        <img className="store-link" src={steam} />
                        <div className="store-label">STEAM</div>
                    </div>
                    <div className="store-button-container">
                        <img className="store-link" src={apple} />
                        <div className="store-label">iOS</div>
                    </div>
                    <div className="store-button-container">
                        <img className="store-link" src={android} />
                        <div className="store-label">ANDROID</div>
                    </div>
                </div>
            </div>
            {showLoginPopup && <LoginPopup onClose={closeLoginPopup} onLoginSuccess={handleLoginSuccess}/>}
            {showPurchasePopup && <PurchasePopup />}
        </div>
    );
}

export default LandingPage;

